import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import { Tooltip } from "@reach/tooltip";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, Slide, toast } from "react-toastify";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { Button } from "../Button";
import { Modal } from "../commom/Modal";
import { Loader } from "../Loader";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { HiOutlineDotsVertical } from "react-icons/hi";
import DuplicationModal from "../templates/DuplicationModal";
import { UnblockModal } from "./UnblockModal";

export const OrdersTableActions = (props: any) => {
    const data = props?.row?.original;

    // hooks
    const { t } = useTranslation();
    const { user, can, roles } = useAuthContext();
    const { unblockOrCancelOrder, createOrder, resendOrderToSemiramis, deleteOrder } = useOrderMutations();
    // state
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const [openDuplicateOrder, setOpenDuplicateOrder] = useState<any>(false);
    const [cancelOrder, setCancelOrder] = useState<any>(false);

    const handleSendOrder = async () => {
        try {
            await resendOrderToSemiramis.mutateAsync({ order_id: data.id });
            toast.success(t("Ordine processato correttamente"));
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    const handleDeleteOrder = async () => {
        try {
            await deleteOrder.mutateAsync({ order_id: data.id });
            toast.success(t("Ordine eliminato"));
        } catch (err) {
            toast.error(t("Qualcosa è andato storto"));
        }
    };

    return (
        // first condition checks if the order is blocked and the second if it has been sent to semiramis or not
        <>
            {(unblockOrCancelOrder.isLoading || createOrder.isLoading || deleteOrder.isLoading || resendOrderToSemiramis.isLoading) && <Loader />}

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {/* DUPLICATE ORDER MODAL  */}
            <DuplicationModal
                label={t("Duplica ordine")}
                modalState={openDuplicateOrder}
                setModalState={setOpenDuplicateOrder}
                data={data}
                type="order"
            />

            {
                cancelOrder &&
                <Modal title={t("Cancella ordine")} open={cancelOrder} onClose={setCancelOrder}>
                    <p>{t("Cancella ordine dalla lista?")}</p>
                    <div className="text-right space-x-2 mt-4">
                        <Button variant={Button.variant.text} onClick={() => setCancelOrder(false)}>{t("Annulla")}</Button>
                        <Button variant={Button.variant.primary} onClick={() => handleDeleteOrder()}>{t("Procedi")}</Button>
                    </div>
                </Modal>
            }

            {
                openUnblockModal &&
                <UnblockModal
                    data={data}
                    openUnblockModal={openUnblockModal}
                    row_id={props.row.original.id}
                    setOpenUnblockModal={setOpenUnblockModal}
                />
            }

            {/* <Button variant={Button.variant.secondary} onClick={() => setOpenUnblockModal(true)}>
                Sblocca/Storna
            </Button> */}

            <Menu>
                <MenuButton>
                    <Tooltip
                        label={"actions"}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                        }}
                    >
                        <span>
                            <HiOutlineDotsVertical className="text-lg cursor-pointer" />
                        </span>
                    </Tooltip>
                </MenuButton>

                <MenuList className="offers-dropdown">
                    <MenuItem
                        className="offers-dropdown-items offers-dropdown-items-selected"
                        onSelect={() => setOpenDuplicateOrder(true)}
                    >
                        {t("Duplica ordine")}
                    </MenuItem>
                    {((!roles(user?.constants?.external_roles) &&
                        !data?.semiramis_number &&
                        !data?.canceled_at) ||
                        (data?.blocked && !data?.canceled_at)) && (
                            <>
                                {(data?.blocked && data?.blocks?.filter((block: any) => !block.unblocked_at && !block.canceled_at)?.length > 0) && (
                                    <MenuItem
                                        className="offers-dropdown-items offers-dropdown-items-selected"
                                        onSelect={() => setOpenUnblockModal(true)}
                                    >
                                        {t("Sblocca ordine")}
                                    </MenuItem>
                                )}
                                {!data?.semiramis_number && !data?.blocked && (
                                    <MenuItem
                                        className="offers-dropdown-items offers-dropdown-items-selected"
                                        onSelect={handleSendOrder}
                                    >
                                        {t("Rinvia ordine")}
                                    </MenuItem>
                                )}
                                {!data?.semiramis_number &&
                                    can(["can_delete_orders"]) && (
                                        <MenuItem
                                            className="offers-dropdown-items offers-dropdown-items-selected"
                                            onSelect={() => setCancelOrder(true)}
                                        >
                                            {t("Elimina ordine")}
                                        </MenuItem>
                                    )}
                            </>
                        )}
                </MenuList>
            </Menu>
        </>
    );
};

