import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImFilesEmpty } from "react-icons/im";
import { Title } from "../components/Title";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import useShippings from "../api/shippings/useShippings";
import ShippingsSearchBar, { ShippingsSearchParams } from "../components/shippings/ShippingsSearchBar";
import { ShippingsTable } from "../components/shippings/ShippingsTable";
import SubRowAsync from "../components/carts/SubRowAsync";
import { useSearchParams } from "react-router-dom";
import { Section } from "../components/Section";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { Slide, toast, ToastContainer } from "react-toastify";

const Shippings = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { roles } = useAuthContext();

    // state
    const [canFetch, setCanFetch] = useState<boolean>(roles(["CLIENT", "CLIENT_LIMITED", "TS"]));

    // react query
    const { shippings, isLoading, isFetching, isError } = useShippings(Object.fromEntries(searchParams.entries()), canFetch);

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="shipping/shippings" />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (searchParams.has("page")) {
            setCanFetch(true)
        }
    }, []);

    useEffect(() => {
        if (isError) {
            toast.error(t("Verifica che i dati inseriti nella form siano corretti"));
        }
    }, [isError]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            {(isLoading || isFetching) && canFetch && <Loader />}

            <Title variant={Title.variant.primary}>{t("Le mie spedizioni")}</Title>

            <Section title={"Cerca spedizione"} className="mb-10">
                <ShippingsSearchBar setSearchParams={setSearchParams} setCanFetch={setCanFetch} />
            </Section>

            <>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
                {
                    shippings?.data?.length > 0 ?
                        <>
                            <ShippingsTable
                                items={shippings?.data}
                                setSearchParams={setSearchParams}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                            <Pagination
                                currentPage={parseInt(searchParams.get("page") ?? "1", 10)}
                                totalCount={shippings?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            {canFetch ? (
                                <p className="text-2xl text-gray-600">{t("Nessuna spedizione trovata")}</p>
                            ) : (
                                <p className="text-2xl text-gray-600">{t("Imposta un filtro per effettuare una ricerca.")}</p>
                            )}
                        </div>
                }
            </>
        </div>
    );
};

export default Shippings;
