import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useExpanded, Column, useSortBy, usePagination } from "react-table";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { useShippingContext } from "../../hooks/use-context/useShippingContext";
import useCartsMutations from "../../api/carts/useCartsMutations";
import { CartsTableActions } from "./CartsTableActions";
import { SortableHeader } from "../commom/SortableHeader";
import { FaPencilAlt } from "react-icons/fa";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { ToastContainer, Slide, toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { Tooltip } from "@reach/tooltip";
import { CartSearchParams } from "./CartsSearchBar";

// CARTLIST TABLE COMPONENT
export default function CartsListTable({ renderRowSubComponent, items, setSearchParams }: any) {
    type Cart = {
        id: number,
        name: string,
        client_name: string,
        technical_salesman: string,
        heading: any,
        customer: any,
        created_at: any,
        updated_at: string,
    };

    const { t } = useTranslation();

    const { user, roles } = useAuthContext();
    const { resetShippingContext } = useShippingContext();
    const { deleteCart, changeCartName } = useCartsMutations();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);
    const [searchParams] = useSearchParams();

    // set hidden columns
    let hiddenColumns: string[];
    if (roles(user?.constants?.external_roles)) {
        hiddenColumns = ["technical_salesman"];
    } else {
        hiddenColumns = [];
    }

    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<Cart>> = useMemo(
        () => [
            {
                id: "expander",
                Header: "",
                disableSortBy: true,
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row

                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            tabIndex: 0
                        })}
                    >
                        {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                    </span>
            },
            {
                Header: t("Nome carrello").toString(),
                accessor: "name",
                disableSortBy: true,
                Cell: (props) => {
                    const [openChangeDraftName, setOpenChangeDraftName] = useState(false);
                    const [cartName, setCartName] = useState("");
                    const [error, setError] = useState("");

                    const handleOpenModal = () => {
                        setCartName(props.row.original.name);
                        setOpenChangeDraftName(true);
                    };

                    const handleChangeDraftName = async () => {
                        if (cartName.length <= 0) {
                            setError(t("Il nome è obbligatorio"));
                            return;
                        }

                        const params = { cart_id: props.row.original.id, cart_name: cartName };

                        try {
                            await changeCartName.mutateAsync(params);
                            toast.success(t("Modifica effettuata con successo"));
                            setOpenChangeDraftName(false);
                        } catch (err) {
                            toast.error(t("Qualcosa è andato storto"));
                            setOpenChangeDraftName(false);
                        }
                    };

                    return (
                        <>
                            <Modal title={t("Modifica nome carrello")} open={openChangeDraftName} onClose={setOpenChangeDraftName}>
                                <div className="mb-4">
                                    <label htmlFor="cart_name">{t("inserisci nome carrello")}</label>
                                    <input type="text" placeholder={t("Nome carrello")} onChange={(e) => setCartName(e.target.value)} value={cartName} />
                                    {error.length > 0 && <p className='text-rose-500 text-xs mt-2'>{error}</p>}
                                </div>

                                <div className="text-right space-x-4">
                                    <Button type='button' variant={Button.variant.text} onClick={() => setOpenChangeDraftName(false)}>{t("Annulla")}</Button>
                                    <Button onClick={handleChangeDraftName}>{t("Salva")}</Button>
                                </div>
                            </Modal>


                            <div className="flex items-center gap-x-1 underline font-normal">
                                <Link to={`/carts/${props.row.original.id}`}>
                                    <span>{props.row.original.name}</span>
                                </Link >
                                <Tooltip
                                    label={t("Modifica nome carrello")}
                                    style={{
                                        backgroundColor: "#F1F5F6",
                                        borderRadius: "3px",
                                        fontWeight: "300",
                                        marginRight: "20px",
                                        zIndex: 50
                                    }}
                                >
                                    <span>
                                        <FaPencilAlt className="cursor-pointer" onClick={handleOpenModal} />
                                    </span>
                                </Tooltip>
                            </div>
                        </>
                    );
                }
            },
            {
                Header: t("Utente").toString(),

                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    return <span>{props?.row?.original?.user_name || "-"}</span>;
                }
            },
            {
                Header: t("Codice cliente").toString(),
                disableSortBy: true,
                Cell: (props: any) => {
                    const customer = props.row.original.customer;

                    if (customer?.type === "DUMMY CUSTOMER") return null;

                    return <span>{customer?.code}</span>;
                }
            },
            {
                Header: t("Nome cliente").toString(),
                accessor: "client_name",
                disableSortBy: true,
                Cell: (props) => {
                    return <span className="uppercase">{props.row.original.heading ? props.row.original.heading?.name?.toUpperCase() : props.row.original.client_name?.toUpperCase()}</span>;
                }
            },
            {
                Header: t("Technical Salesman").toString(),
                accessor: "technical_salesman",
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    const customer = props.row.original.customer;

                    return <span>{customer?.ts_fullname}</span>;
                }
            },
            {
                Header: (props) => {
                    return <SortableHeader props={props} title={t("Data creazione").toString()} />;
                },
                accessor: "created_at",
                Cell: (props) => {
                    // console.log(props);

                    const created_at = new Date(props.row.original.created_at);
                    const date = props.dateFormat.format(created_at);

                    return (
                        <span>
                            {date}
                        </span>
                    );
                }
            },
            {
                Header: " ",
                className: "border-b p-4",
                Cell: CartsTableActions
            },
        ],
        [t, user?.id, resetShippingContext]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            manualSortBy: true,
            disableSortRemove: true,
            initialState: {
                hiddenColumns: hiddenColumns,
                pageSize: 10
            },
            dateFormat,
            deleteCart
        },
        useSortBy,
        useExpanded,
        usePagination,
    );

    useEffect(() => {
        if (["created_at", "updated_at"].includes(sortBy[0]?.id))
            setSearchParams(() => ({ ...Object.fromEntries(searchParams.entries()), sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}`, page: 1 }));
    }, [sortBy]);

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            <table {...getTableProps()} className="w-full text-xs font-light border overflow-x-scroll block lg:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="px-4 py-3 text-left"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className })}
                                                className="border-b px-4 py-3"
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
