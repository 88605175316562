import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination } from "react-table";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { Status, StatusVariant } from "../Status";
import { AvailabilityCell } from "./AvailabilityCell";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import classNames from "classnames";
import { InfoTooltip } from "./InfoTooltip";
import { AvailabilityTooltip } from "../cart/AvailabilityTooltip";
import { Tooltip } from "@reach/tooltip";
import client from "../../api/client";
import { IoIosWarning } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

type SummaryTableProps = {
    items: any,
    total_products: any,
    hiddenColumns: string[],
    customer: any,
    cart: any,
};

const SummaryTable: React.FC<SummaryTableProps> = ({ items, total_products, hiddenColumns, customer, cart }) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();
    const { pathname } = useLocation();
    const location = pathname?.split("/")[1];


    // this alg is needed to hide change product modal in page offer details
    const isSummary = true;
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setTableData(cart?.data?.items);
    }, [cart]);

    const fetchUpdatedData = async () => {
        try {
            setIsLoading(true);

            const res = await client.get(`carts/${cart?.data?.id}/max-discount`);

            setTableData((prevData: any) => {
                const newData = prevData.map((row: any) => {
                    const updatedRow = res?.data?.data?.find((updated: any) => updated.id === row.id);
                    return updatedRow ? { ...row, ...updatedRow } : row;
                });
                return newData;
            });
        } catch (error) {
            console.error("Error fetching updated data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (cart?.data?.customer?.check_maximum_discount && location === "carts") {
            fetchUpdatedData();
        }
    }, [cart]);


    // table data
    const data = useMemo(
        () => {
            return items.filter((item: any) => {
                return !user?.constants?.extra_services?.includes(item?.code) && !user?.constants?.is_freight_cost?.includes(item?.code);
            });
        },
        [items]
    );

    const columns: any = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    return (
                        <div className="flex gap-1 items-center">
                            <div>
                                <div className='mx-auto mb-1 text-center text-2xl'>
                                    <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                                </div>
                                {
                                    props.row.original.isinpricelistrb ?
                                        <span className={classNames("block text-center hover:underline", { "text-rose-500": !!props.row.original.is_invalid })}>
                                            <a href={`https://www.rothoblaas.com/search?q=${props.cell.row.original.code}&s=eservice`} target="_blank" rel="noreferrer">{props.cell.row.original.code.toUpperCase()}</a>
                                        </span>
                                        :
                                        <span className="block text-center hover:underline">{props.cell.row.original.code}</span>
                                }
                            </div>
                        </div>
                    );
                }
            },
            {
                Header: t("Quantità").toString(),
                accessor: "qty",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id);

                    return (
                        <>
                            {numberFormat.format(props.row.original.qty)}
                        </>
                    );
                }
            },
            {
                Header: t("Disponibilità").toString(),
                id: "reservedqty",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const reservedQty = props.row.original.reservedqty;
                    const availabilityCoverDate = props.row.original.availabilityInfo?.availabilityCoverDate;

                    const numberFormat = new Intl.NumberFormat(customer?.language_id);

                    // show reserved qty only for under_evalutaion(1) & partially_shipped(3) orders
                    if (["1", "3"].includes(props.row.original.status)) {
                        return (
                            <>
                                {
                                    reservedQty > 0 &&
                                    <div className="flex items-center gap-x-1">
                                        <Status variant={Status.variant["green"]}>
                                            <InfoTooltip>{t("Prodotto riservato")}</InfoTooltip>
                                        </Status>
                                        {numberFormat.format(reservedQty)}
                                    </div>
                                }

                                {
                                    ((qty - reservedQty) > 0) &&
                                    <div className="flex items-center gap-x-1">
                                        <Status variant={Status.variant["red"]}>
                                            <AvailabilityTooltip availability_date={availabilityCoverDate} />
                                        </Status>

                                        {/* QTY */}
                                        {numberFormat.format((qty - reservedQty))}
                                    </div>
                                }
                            </>
                        );
                    } else {
                        return "-";
                    }
                }
            },
            {
                Header: t("Stato").toString(),
                id: "status",
                className: "text-left p-4 border-b",
                Cell: (props: any) => {
                    const orderStatus = props.row.original.status;
                    const status = EservicesStatus.order.find((status) => status.status === orderStatus);

                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    return <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>;
                }
            },
            {
                Header: t("Disponibilità").toString(),
                id: "availability",
                className: "text-left p-4 border-b",
                Cell: AvailabilityCell
            },
            {
                Header: t("Magazzino").toString(),
                className: "text-left p-4 border-b",
                cellClasses: "text-left p-4",
                Cell: (props: any) => {
                    const storagearea_id = props.row.original.storagearea_id ?? props?.cart?.data?.customer?.technicalSale?.storageArea?.code;
                    return <Tooltip
                        label={props.row.original?.storageArea?.description ?? props.cart?.data?.customer?.technicalSale?.storageArea?.description}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50,
                        }}
                    >
                        <span className="font-light cursor-default">{storagearea_id ?? "-"}</span>
                    </Tooltip>
                },
            },
            {
                Header: t("Sconto %").toString(),
                accessor: "discount_1",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_1)}
                        </>
                    );
                }
            },
            {
                Header: t("Sconto 2 %").toString(),
                accessor: "discount_2",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return (
                        <>
                            {numberFormat.format(props.row.original.discount_2)}
                        </>
                    );
                }
            },
            {
                Header: t("Prezzo unitario ({{currency}})", { currency: customer?.currency }).toString(),
                accessor: "pricevalue",
                disableSortBy: true,
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const fractionDigits = getCurrencyDecimals(customer?.currency) <= 0 ? 2 : 5;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });

                    return numberFormat.format(props.cell.row.original.net_price);
                }
            },
            {
                Header: t("Prezzo netto unitario ({{currency}})", { currency: customer?.currency }).toString(),
                accessor: "net_price",
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const fractionDigits = getCurrencyDecimals(customer?.currency) <= 0 ? 2 : 5;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });

                    return (
                        <>
                            {numberFormat.format(props.row.original.net_price)}
                        </>
                    );
                }
            },
            {
                Header: t("Totale scontato ({{currency}})", { currency: customer?.currency }).toString(),
                id: "discounted_total",
                className: "text-right p-4 border-b",
                Cell: (props: any) => {
                    const qty = props.row.original.qty;
                    const net_price = props.row.original.net_price;

                    const total = qty * net_price;
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null) });

                    return <>{numberFormat.format(total)}</>;
                },
                Footer: () => {
                    const numberFormat = new Intl.NumberFormat(customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(items?.length ? items[0].currency : null) });
                    return <>{numberFormat.format(total_products)}</>;
                }
            },
        ],
        [t, items, total_products, hiddenColumns, cart, customer]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data: tableData, initialState: { hiddenColumns: hiddenColumns, pageSize: 100 }, cart, isSummary }, useSortBy, usePagination);

    return (
        <div className='overflow-y-auto overflow-visible min-h-min max-h-[750px] border border-b-0'>
            <table {...getTableProps()} className="w-full text-xs font-light">
                <thead className='sticky top-0 bg-white'>
                    {headerGroups.map(headerGroup => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className="relative"
                        >
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row);

                        if (isLoading) {
                            return (
                                <React.Fragment key={row.id}>
                                    <tr>
                                        <td colSpan={row.cells.length}>
                                            <Skeleton className={`col-span-${row.cells.length}`} width={"100%"} height={89} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )
                        }

                        return (
                            <React.Fragment key={row.id}>
                                <tr className={`${row?.original?.maxDiscountExceeded && "[&>td]:bg-error-lighter"}`} {...row.getRowProps()}>
                                    {row.cells.map((cell: any, index: number) => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {
                                    (row?.original?.maxDiscountExceeded && location !== "orders") &&
                                    <tr className={`${row?.original?.maxDiscountExceeded && "bg-error-light"}`}>
                                        <td colSpan={row.cells.length} className="py-2">
                                            <div className="flex justify-center">
                                                <div className="flex items-center gap-2.5">
                                                    <IoIosWarning className="fill-red-500" />
                                                    <span>{t("Sconto massimo superato")}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
                <tfoot className='sticky bottom-0 bg-white'>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}
                            className="text-left relative"
                        >
                            {group.headers.map(column => (
                                <td
                                    {...column.getFooterProps()}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Footer")}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    );
};

export default SummaryTable;
