import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronRight } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type StepperProps = {
    activePath: string | undefined;
    disabled?: boolean;
};

type Step = {
    step: number,
    label: string,
    pattern: RegExp,
    path: string
};

export default function Stepper({ activePath, disabled = false }: StepperProps) {
    const { t } = useTranslation();
    const params = useParams();
    const { user, roles, can } = useAuthContext();

    const getCartIdRegex = /\d+/;

    const STEPS: any = [
        {
            step: 1,
            label: !can(["can_make_order"]) ? t("Crea carrello").toString() : (!roles(user?.constants?.external_roles) ? t("Crea ordine o offerta").toString() : t("Crea ordine").toString()),
            pattern: /^\d*$/,
            path: `/carts/${params["*"]?.match(getCartIdRegex)}`
        },
        {
            step: 2,
            label: !can(["can_make_order"]) ? t("Procedi con il carrello").toString() : (!roles(user?.constants?.external_roles) ? t("Procedi con l'ordine o offerta") : t("Procedi con l'ordine").toString()),
            pattern: /^\d*\/shipping$/,
            path: `/carts/${params["*"]?.match(getCartIdRegex)}/shipping`
        },
        {
            step: 3,
            label: t("Riepilogo").toString(),
            pattern: /^\d*\/summary$/,
            path: "summary"
        }
    ];

    const [activeStep, setActiveStep] = useState<Step>(STEPS[1]);

    const testPath = (path: string, pattern: RegExp) => {
        return pattern.test(path);
    };

    const getActiveStep = (availableSteps: Step[]) => {
        for (let i = 0; i < availableSteps.length; i++) {
            const flag = testPath(activePath!, availableSteps[i].pattern);
            if (flag) {
                setActiveStep(availableSteps[i]);
            }
        }
    };

    useEffect(() => {
        getActiveStep(STEPS);
    }, [activePath]);

    return (
        <>
            {
                !disabled &&
                <div className="flex flex-col sm:flex-row text-sm mb-6">
                    {
                        STEPS.map((step: any) => (
                            <div key={step.step} className={`flex whitespace-nowrap items-center ${step.step !== activeStep?.step ? "text-gray-200" : ""}`}>
                                {
                                    (activeStep.step > step?.step && !disabled) ?
                                        <Link to={step.path} key={step.step} className="hover:text-gray-300">
                                            {step.label}
                                        </Link> :
                                        <div>
                                            {step.label}
                                        </div>
                                }
                                {
                                    step.step < 3 && <HiChevronRight className="text-2xl mx-2" />
                                }
                            </div>
                        ))
                    }
                </div>
            }
        </>
    );
}
