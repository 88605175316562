import { useTranslation } from "react-i18next";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { useEffect } from "react";
import useBlockedOrder from "../../api/orders/useBlockedOrder";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import Table from "../Table";
import { convertMiliseconds, getExpiredDays } from "../../utils/utils";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import Switch from "../commom/Switch";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOrderMutations } from "../../api/orders/useOrderMutations";
import { toast } from "react-toastify";
import { CgProfile } from "react-icons/cg";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

type Props = {
    openUnblockModal: boolean;
    setOpenUnblockModal: any;
    row_id: any;
    data: any;
}

interface Block {
    id: string;
    reason: string;
    action: 'unblock' | 'cancel' | null;
    unblock_reason: string | null;
    detailsOpen: boolean;
    checked: boolean;
    can_be_unblocked: boolean;
    unblockable_by: string;
    unblocked_at: string;
    canceled_at: string;
}

interface FormData {
    blocks: Block[];
}

export const UnblockModal = ({ data, openUnblockModal, setOpenUnblockModal, row_id }: Props) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    const { blockedOrder, isLoading } = useBlockedOrder(row_id, openUnblockModal);
    const { unblockOrCancelOrder } = useOrderMutations();
    const totalBlockedAmount = blockedOrder?.data?.customer?.unprocessedOrders?.reduce((acc: number, obj: any) => acc + parseFloat(obj.total_net_price), 0);
    const residualCredit = blockedOrder?.data?.customer?.finance?.creditlimit - blockedOrder?.data?.customer?.finance?.unpaid - blockedOrder?.data?.customer?.finance?.undelivered - blockedOrder?.data?.customer?.finance?.deliverednotinvoiced - blockedOrder?.data?.customer?.finance?.invoicednottransferred - totalBlockedAmount;
    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // columns
    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice"
                    ? getExpiredDays(props.row.original.due_date) > 0
                        ? convertMiliseconds(
                            getExpiredDays(props.row.original.due_date),
                            "d"
                        )
                        : ""
                    : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    // useForm
    const { register, handleSubmit, formState: { errors, isDirty }, control, resetField, setValue, getValues } = useForm<FormData>({
        defaultValues: {
            blocks: [],
        }
    });

    const { fields, update } = useFieldArray({
        control,
        name: "blocks",
        keyName: "_id",
    });

    let numberFormat: any = null;
    try {
        numberFormat = new Intl.NumberFormat(data?.customer?.language_id, { style: "currency", currency: data?.customer?.currency, minimumFractionDigits: getCurrencyDecimals(data?.customer?.currency), maximumFractionDigits: getCurrencyDecimals(data?.customer?.currency) });
    } catch (e) {
        console.log("Error Catched", data?.customer);
    }

    const handleSwitchChange = (checked: boolean, index: number) => {
        resetField(`blocks.${index}.action`);
        resetField(`blocks.${index}.unblock_reason`);
    }

    const formattedData = (formData: any) => {
        const filteredData = formData.blocks.filter((block: any) => !!block?.action);

        const formatedData = {
            order_id: data?.id,
            blocks: filteredData
        }

        return formatedData;
    }

    const onSubmit = async (formData: any) => {
        const data = formattedData(formData);

        setOpenUnblockModal(false);

        await toast.promise(unblockOrCancelOrder.mutateAsync(data), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("La modifica non è stata effettuata");
                    }
                }
            }
        });
    }

    useEffect(() => {
        if (blockedOrder) {
            const newArr = [...blockedOrder.data.blocks];
            const mapedArr = newArr.map((item: any) => ({
                id: item.id,
                reason: item.reason,
                action: null,
                unblock_reason: null,
                checked: false,
                detailsOpen: false,
                can_be_unblocked: item.can_be_unblocked,
                unblockable_by: item.unblockable_by,
                unblocked_at: item.unblocked_at,
                canceled_at: item.canceled_at,
            }));

            setValue('blocks', mapedArr);
        }
    }, [blockedOrder, setValue]);

    return (
        <>
            {
                openUnblockModal &&
                <Modal
                    open={openUnblockModal}
                    title={t("Sblocca ordine")}
                    onClose={setOpenUnblockModal}
                >
                    {
                        isLoading ?
                            <div className="flex flex-col gap-5">
                                <Skeleton width={'100%'} height={135} />
                                <Skeleton width={'100%'} height={110} />
                            </div>
                            :
                            <div className="space-y-4">
                                <div className="bg-tertiary space-y-2 p-4 font-light rounded">
                                    <div>
                                        {t("Cliente")}:{" "}
                                        {blockedOrder?.data?.customer?.name} -{" "}
                                        {blockedOrder?.data?.customer?.code}
                                    </div>
                                    <p>
                                        {t("TS")}:{" "}
                                        {blockedOrder?.data?.customer?.technicalSale?.name}
                                    </p>
                                    <p>
                                        {t("ASM")}:{" "}
                                        {blockedOrder?.data?.customer?.areaManager?.name}
                                    </p>
                                    <p>
                                        {t("Totale ordini bloccati E-service")}:{" "}
                                        {numberFormat?.format(totalBlockedAmount)}
                                    </p>
                                </div>

                                <div>
                                    <span className="font-normal">{t("Attenzione: aggiornamento dati in tempo reale, i valori potrebbero differire dalla mail di sblocco")}.</span>
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                                    <div className="bg-neutral-weaker space-y-4 p-6 rounded">
                                        {
                                            fields.map((field, index) => {
                                                if (field?.unblocked_at || field?.canceled_at) { return null }

                                                return <div className="flex flex-col bg-white border p-4 rounded" key={field.id}>
                                                    <div className="flex justify-between items-center">
                                                        <div className="flex flex-wrap gap-2">
                                                            <div className="flex gap-2 items-center">
                                                                {/* {
                                                            (field.can_be_unblocked &&
                                                                field.detailsOpen) ?
                                                                <FaMinus
                                                                    className="w-4 h-4 cursor-pointer"
                                                                    onClick={() => update(index, { ...getValues(`blocks.${index}`), detailsOpen: !getValues(`blocks.${index}.detailsOpen`) })}
                                                                />
                                                                :
                                                                <FaPlus
                                                                    className="w-4 h-4 cursor-pointer"
                                                                    onClick={() => update(index, { ...getValues(`blocks.${index}`), detailsOpen: !getValues(`blocks.${index}.detailsOpen`) })}
                                                                />
                                                        } */}
                                                                <span className="font-semibold">
                                                                    {t(field?.reason === "max-discount" ? field?.reason : field?.reason === "creditlimit" ? "Fuori fido" : "Insoluto")}
                                                                </span>
                                                            </div>
                                                            {
                                                                field?.unblockable_by &&
                                                                <div className="flex gap-1 items-center border px-2 rounded shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)]">
                                                                    <CgProfile />
                                                                    <span>{t("Autorizzabile da:")} {field?.unblockable_by}</span>
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="flex items-center [&>label]:pb-0">
                                                            <Switch
                                                                disabled={!field?.can_be_unblocked}
                                                                checked={field.checked}
                                                                setChecked={() => {
                                                                    handleSwitchChange(field.checked, index);
                                                                    update(index, { ...getValues(`blocks.${index}`), checked: !getValues(`blocks.${index}.checked`) })
                                                                }}
                                                                label={t("Autorizza") + " / " + t("Nega")}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={`${field.checked ? "max-h-[1000px]" : "max-h-0"} transition-all duration-400 overflow-hidden space-y-4`}>
                                                        <div className={`${field.checked ? "opacity-100" : "opacity-0"} mt-4 transition-opacity duration-400 space-y-2`}>
                                                            <span className="font-normal">{t("Specificare se procedere con lo sblocco o lo storno")}</span>
                                                            <div className="flex gap-4 items-center">
                                                                <div className="flex gap-2">
                                                                    <input
                                                                        className="accent-black"
                                                                        type="radio"
                                                                        id={`unblock_${index}`}
                                                                        value={"unblock"}
                                                                        {...register(`blocks.${index}.action`, { required: field.checked })}
                                                                    />
                                                                    <label className="p-0 cursor-pointer" htmlFor={`unblock_${index}`}>{t("Autorizza")}</label>
                                                                </div>
                                                                <div className="flex gap-2">
                                                                    <input
                                                                        className="accent-black"
                                                                        type="radio"
                                                                        id={`cancel_${index}`}
                                                                        value={"cancel"}
                                                                        {...register(`blocks.${index}.action`, { required: field.checked })}
                                                                    />
                                                                    <label className="p-0 cursor-pointer" htmlFor={`cancel_${index}`}>{t("Nega")}</label>
                                                                </div>
                                                            </div>
                                                            {errors.blocks?.[index]?.action && <span className="text-rose-500 text-sm mt-1">{t("Questo campo è obbligatorio")}</span>}
                                                        </div>

                                                        {/* REASON */}
                                                        <div className={`${field.checked ? "opacity-100" : "opacity-0"} mt-4 transition-opacity duration-400`}>
                                                            <label className="font-normal">
                                                                {t("Inserisci la motivazione dello sblocco/storno:")}
                                                            </label>
                                                            <Controller
                                                                name={`blocks.${index}.unblock_reason`}
                                                                control={control}
                                                                rules={{
                                                                    required: field.checked,
                                                                    minLength: {
                                                                        value: 20,
                                                                        message: "La motivazione deve avere minimo 20 caratteri",
                                                                    },
                                                                }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <textarea
                                                                            className={`${fieldState.error ? "border-rose-500 outline-rose-500" : ""}`}
                                                                            onChange={(e) => {
                                                                                field.onChange(e);
                                                                            }}
                                                                            onBlur={field.onBlur}
                                                                        />

                                                                        {fieldState?.error && (
                                                                            <span className="text-rose-500 text-sm mt-1">
                                                                                {fieldState.error.message || t("Questo campo è obbligatorio")}
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* DIVIDER */}
                                                    {field.checked && <hr className="my-2" />}

                                                    <div className={`${field.checked ? "max-h-[1000px]" : "max-h-0"} transition-all duration-400 overflow-hidden`}>
                                                        {
                                                            field?.reason === "max-discount" ? <div className={`${field.checked ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                                <div className="w-full overflow-x-scroll">
                                                                    <table className="w-full border-collapse border overflow-x-scroll">
                                                                        <thead className="bg-tertiary">
                                                                            <tr className="text-left border-b">
                                                                                <th className="px-4 py-2 whitespace-nowrap">{t("Codice articolo")}</th>
                                                                                <th className="px-4 py-2 whitespace-nowrap">{t("Quantità")}</th>
                                                                                <th className="px-4 py-2 whitespace-nowrap text-right">{t("Sconto totale")}</th>
                                                                                <th className="px-4 py-2 whitespace-nowrap text-right">{t("Importo")}</th>
                                                                                <th className="px-4 py-2 whitespace-nowrap text-right">{t("Massimo sconto")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {blockedOrder?.data?.blocks
                                                                                ?.filter((block: any) => block?.reason === "max-discount")
                                                                                ?.flatMap((block: any) =>
                                                                                    block?.blockedItems?.map((item: any) => (
                                                                                        <tr key={item?.id} className="px-4 py-2">
                                                                                            <td className="px-4 py-2">{item?.product?.code}</td>
                                                                                            <td className="px-4 py-2">{item?.qty}</td>
                                                                                            <td className="px-4 py-2 text-right">{item?.currentDiscount}</td>
                                                                                            <td className="px-4 py-2 text-right">{numberFormat.format(item?.net_price * item?.qty)}</td>
                                                                                            <td className="px-4 py-2 text-right bg-tertiary">{item?.maxDiscount}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div> : <div className="grid grid-cols-2">
                                                                {/* Display finance data and payment summary */}
                                                                <div className={`${field.checked ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                                    <p>
                                                                        {t("Insoluto")}:{" "}
                                                                        <span className={`${blockedOrder?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                                                                            {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaidexpired) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Partite aperte")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.unpaid) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Fido")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(blockedOrder?.data?.customer?.finance?.creditlimit) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Ordine attuale")}:{" "}
                                                                        {numberFormat ? numberFormat?.format(blockedOrder?.data?.total_net_price) : ""}
                                                                    </p>
                                                                    <p>
                                                                        {t("Totale ordini bloccati E-service")}:{" "}
                                                                        {numberFormat?.format(totalBlockedAmount)}
                                                                    </p>
                                                                </div>
                                                                <div className={`${field.checked ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                                    {/* Display more finance and rating data here */}
                                                                    <p>
                                                                        {t("Fido residuo")}:{" "}
                                                                        <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                                                            {numberFormat ? numberFormat?.format(residualCredit) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Totale fatture ivate ultimi 24 mesi")}:{" "}
                                                                        <span>
                                                                            {numberFormat ? numberFormat.format(blockedOrder?.data?.customer?.customerInvoices?.reduce((acc: number, obj: any) => acc + obj.netamount, 0)) : ""}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Rothoblaas Rating")}:{" "}
                                                                        <span className={blockedOrder?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        {t("Finance Rating")}:{" "}
                                                                        <span
                                                                            className={blockedOrder?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : blockedOrder?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                                                            {blockedOrder?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className={`${field.checked ? "opacity-100" : "opacity-0"} transition-opacity duration-400 col-span-2 mt-4`}>
                                                                    {blockedOrder && (
                                                                        <Table
                                                                            variants={Table.variants.expandable}
                                                                            label="Estratto conto"
                                                                            data={blockedOrder?.data?.customer?.open_nav_payments}
                                                                            columns={invoicesColumns}
                                                                            emptyStateMessage="Nessuna fattura aperta"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                    <div className="text-right space-x-2">
                                        <Button
                                            variant={Button.variant.text}
                                            onClick={() => setOpenUnblockModal(false)}
                                        >
                                            {t("Annulla")}
                                        </Button>
                                        <Button
                                            variant={Button.variant.primary}
                                            isDisabled={fields?.every((field: any) => !field?.checked)}
                                        >
                                            {t("Conferma")}
                                        </Button>
                                    </div>
                                </form>
                            </div>
                    }
                </Modal>
            }
        </>
    )
}
