import { useTranslation } from "react-i18next"
import { Button } from "../../../Button";
import { useCartItemsMutations } from "../../../../api/carts/useCartItemsMutations";
import { Loader } from "../../../Loader";
import { toast } from "react-toastify";

type AlternativeWarehouseProps = {
    warehouse: any;
    isWarehouseSelected: boolean;
    setIsWarehouseSelected: any;
    closeModal: () => void;
    productId: any;
    cartId: any;
    storageareaId: string;
    unavailableQuantity: string;
}

export const AlternativeWarehouse = ({ warehouse, isWarehouseSelected, setIsWarehouseSelected, closeModal, cartId, productId, storageareaId, unavailableQuantity }: AlternativeWarehouseProps) => {
    const { t } = useTranslation();
    const { changeWarehouse } = useCartItemsMutations();

    const handleSubmit = async () => {
        const body = {
            storagearea_id: storageareaId,
            quantity: unavailableQuantity,
        };

        await toast.promise(changeWarehouse.mutateAsync({ cart_id: cartId, product_id: productId, body }), {
            success: t("Modifica effettuata con successo"),
            error: t("La modifica non è stata effettuata")
        });
        closeModal();
    };

    return <>
        {changeWarehouse.isLoading && <Loader />}

        <div className="bg-neutral-weaker flex flex-col gap-4 p-6">
            {
                warehouse?.trans?.description ?
                    <>
                        <span>
                            {t("Ricevi il prodotto da una magazzino alternativo")}
                        </span>
                        <label className={"border cursor-pointer flex gap-6 p-4 bg-white"}>
                            <input type="radio" onChange={() => setIsWarehouseSelected(!isWarehouseSelected)} />
                            <div className="flex flex-col gap-2">
                                <span className="text-secondary uppercase">{warehouse?.trans?.description}</span>
                            </div>
                        </label>

                        <span className="font-light text-sm">
                            {t("Ti informiamo che, in caso di spedizione da un magazzino diverso dal principale, verrà applicato un costo aggiuntivo di 14€ per ogni magazzino utilizzato. Per ulteriori dettagli, ti invitiamo a consultare le nostre condizioni di servizio.")}
                        </span>
                    </>
                    : <span className="font-light text-sm">{t("L'articolo non è disponibile in nessun magazzino alternativo")}</span>
            }

        </div>
        <div className="space-x-2 mt-4">
            <Button variant={Button.variant.primary} onClick={handleSubmit} isDisabled={!isWarehouseSelected}>{t("Conferma")}</Button>
            <Button variant={Button.variant.text} onClick={() => closeModal()}>{t("Annulla")}</Button>
        </div>
    </>
}
