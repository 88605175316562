import { createContext, useState } from "react";

type MaxDiscountContextProviderProps = {
    children: React.ReactNode;
};

type MaxDiscountContextType = {
    maxDiscount: any;
    setMaxDiscount: any;
};

export const MaxDiscountContext = createContext<MaxDiscountContextType | null>(null);

export const MaxDiscountProvider = ({ children }: MaxDiscountContextProviderProps) => {
    // state
    const [maxDiscount, setMaxDiscount] = useState<any>([]);

    return (
        <MaxDiscountContext.Provider value={{ maxDiscount, setMaxDiscount }}>
            {children}
        </MaxDiscountContext.Provider>
    );
};
