export const EservicesStatus = {
    order: [
        {
            status: "1",
            variant: "violet",
            description: "In valutazione"
        },
        {
            status: "2",
            variant: "blue",
            description: "In preparazione"
        },
        {
            status: "3",
            variant: "yellow",
            description: "Parzialmente spedito"
        },
        {
            status: "4",
            variant: "green",
            description: "Concluso"
        },
        {
            status: "5",
            variant: "red",
            description: "Non autorizzato"
        },
        {
            status: "6",
            variant: "amber",
            description: "In elaborazione"
        },
        {
            status: "7",
            variant: "red",
            description: "Bloccato"
        },
        {
            status: "8",
            variant: "gray",
            description: "Cancellato"
        },
    ],
    shipping: [
        {
            status: "1",
            variant: "green",
            description: "Concluso"
        },
        {
            status: "2",
            variant: "red",
            description: "Stornato"
        },
    ],
    invoice: [
        {
            status: "1",
            variant: "violet",
            description: "Non pagato"
        },
        {
            status: "2",
            variant: "yellow",
            description: "Parzialmente pagato"
        },
        {
            status: "3",
            variant: "green",
            description: "Completamente pagato"
        },
        {
            status: "4",
            variant: "red",
            description: "Stornato"
        },
    ],
    unblockable_by: [
        {
            status: "1",
            variant: "TS",
            description: "TS"
        },
        {
            status: "2",
            variant: "RSM",
            description: "RSM"
        },
        {
            status: "3",
            variant: "ASM",
            description: "ASM"
        },
        {
            status: "4",
            variant: "DSM",
            description: "DSM"
        },
        {
            status: "5",
            variant: "DIR",
            description: "DIR"
        },
        {
            status: "6",
            variant: "CM",
            description: "CM"
        }
    ],
    complaints: [
        {
            status: "2",
            variant: "green",
            description: "Aperto"
        },
        {
            status: "3",
            variant: "violet",
            description: "Chiuso"
        },
    ]
};
