import { useQuery } from "@tanstack/react-query";
import client from "../client";
import { CartSearchParams } from "../../components/carts/CartsSearchBar"


export default function useCarts(params: CartSearchParams) {
    // GET OFFERS
    const fetchCarts = async (params: CartSearchParams) => {
        const res = await client.get("carts", { params: params });
        return res.data;
    };

    const { data: carts, isLoading, isFetching, isError } = useQuery(["carts", params],
        () => fetchCarts(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    );

    return { carts, isLoading, isFetching, isError };
}
