import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { Link, useSearchParams } from "react-router-dom";
import { useTable, useSortBy, usePagination, Column, useExpanded } from "react-table";
import { EservicesStatus } from "../../constants/EservicesStatus";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../utils/getCurrencyDecimals";
import { SortableHeader } from "../commom/SortableHeader";
import { Status, StatusVariant } from "../Status";
import { OrdersTableActions } from "./OrdersTableActions";
import { Tooltip } from "@reach/tooltip";
import { OrderSearchParams } from "./OrdersSearchBar";
import { InfoTooltip } from "../commom/InfoTooltip";

type OrdersTableProps = {
    items: any,
    setSearchParams: any,
    renderRowSubComponent: any
};

export const OrdersTable = ({ renderRowSubComponent, items, setSearchParams }: OrdersTableProps) => {
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();

    //state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [searchParams] = useSearchParams();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                id: "expander",
                Header: "",
                disableSortBy: true,
                className: "border-b p-4",
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row

                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                            tabIndex: 0
                        })}
                    >
                        {row.isExpanded ? <HiChevronUp className="text-xl" /> : <HiChevronDown className="text-xl" />}
                    </span>
            },
            {
                Header: t("Numero ordine").toString(),
                accessor: "semiramis_number",
                disableSortBy: true,
                className: "border-b p-4 font-normal text-left",
                Cell: (props) => {
                    if (props.row.original.semiramis_number !== "") return <Link to={`${props.row.original.id}`} className="underline">{props.row.original.semiramis_number}</Link>;

                    if (props.row.original.status === '8') {
                        return <div>{props.row.original.id}</div>;
                    } else {
                        return <Link to={`${props.row.original.id}`} className="underline">{props.row.original.id}</Link>;
                    }
                }
            },
            {
                Header: t("Utente").toString(),
                accessor: "user_name",
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    return <span>{props?.row?.original?.user_name || "-"}</span>;
                }
            },
            {
                Header: t("Nota personalizzata").toString(),
                accessor: "client_reference",
                disableSortBy: true,
                maxWidth: 150,
                className: "border-b p-4 text truncate text-left",
                Cell: (props) => {
                    if (!props.row.original.client_reference && !props.row.original.salesOrder?.client_reference) return "-";

                    return <Tooltip
                        label={props.row.original.salesOrder ? props.row.original.salesOrder?.client_reference : props.row.original.client_reference}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50
                        }}
                    >
                        <span>{props.row.original.salesOrder ? props.row.original.salesOrder?.client_reference : props.row.original.client_reference}</span>
                    </Tooltip>;
                }
            },
            {
                Header: t("Codice cliente").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    const customer = props.row.original.customer;

                    if (customer?.type === "DUMMY CUSTOMER") return null;

                    return <span>{customer?.code}</span>;
                }
            },
            {
                Header: t("Nome cliente").toString(),
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    const customer = props.row.original.customer;

                    return <span>{customer?.name}</span>;
                }
            },
            {
                Header: t("Technical Salesman").toString(),
                accessor: "technical_salesman",
                disableSortBy: true,
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    const customer = props.row.original.customer;

                    // return <span>{customer?.ts_code}</span>
                    return <span>{customer?.ts_fullname}</span>;
                }
            },
            {
                Header: (props) => {
                    return <SortableHeader props={props} title={t("Data ordine").toString()} />;
                },
                accessor: "created_at",
                className: "border-b p-4 text-left",
                Cell: (props) => {
                    const created_at = new Date(props.row.original.created_at);
                    const date = props.dateFormat.format(created_at);

                    return (
                        <span>
                            {date}
                        </span>
                    );
                }
            },
            {
                Header: t("Importo senza IVA").toString(),
                accessor: "total_net_price",
                disableSortBy: true,
                className: "border-b p-4 text-right",
                Cell: (props) => {
                    const salesOrder = props.row.original.salesOrder;
                    const customer = props.row.original.customer;

                    let total_net_price;
                    if (salesOrder) {
                        total_net_price = salesOrder.summary.total_order_without_iva;
                    } else {
                        total_net_price = props.row.original.summary?.total_order_without_iva;
                    }

                    const numberFormat = new Intl.NumberFormat(`${customer?.language_id}`, { style: "currency", currency: props?.row.original.currency, minimumFractionDigits: getCurrencyDecimals(props?.row.original.currency), maximumFractionDigits: getCurrencyDecimals(props?.row.original.currency) });

                    return (
                        <span>
                            {numberFormat.format(total_net_price)}
                        </span>
                    );
                }
            },
            {
                Header: t("Stato ordine").toString(),
                className: "border-b p-4 text-left",
                Cell: (props: any) => {
                    const orderStatus = props.row.original.status;
                    const status = EservicesStatus.order.find((status) => status.status === orderStatus);

                    const statusVariant = status?.variant as keyof typeof StatusVariant;

                    if (!statusVariant) return "";

                    return <div className="flex items-center gap-1">
                        <Status variant={Status.variant[statusVariant]}>{t(status?.description ?? "")}</Status>

                        {
                            // status 7 = eservice_blocked
                            (status?.status === "7" && props?.row?.original?.blocks?.length > 0) &&
                            <InfoTooltip>
                                {
                                    props?.row?.original?.blocks?.map((block: any, i: number) => (
                                        <span key={i}>{t(block?.reason)} </span>
                                    ))
                                }
                            </InfoTooltip>
                        }
                    </div>
                }
            },
            {
                Header: t("Autorizzabile da").toString(),
                className: "border-b p-4 text-left",
                accessor: "unblockable_by",
                Cell: (props) => {
                    if (props?.row?.original?.status === '7') {
                        const unblockableBy = props?.row?.original?.blocks?.map((block: any) => {
                            if (block?.unblockable_by === 'CM') {
                                return `${block?.unblockable_by} (${props.row.original.customer.creditManagment.name})`;
                            } else {
                                return block?.unblockable_by;
                            }
                        });
                        return <span>{unblockableBy && unblockableBy.length > 0 ? unblockableBy.join(", ") : "-"}</span>;
                    } else {
                        return "-";
                    }
                }
            },
            {
                Header: " ",
                className: "border-b p-4",
                Cell: (props: any) => OrdersTableActions(props)
            },
        ],
        [t, hiddenColumns]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        state: { sortBy }
    } = useTable({
        columns,
        data,
        defaultColumn,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { hiddenColumns: hiddenColumns, pageSize: 10 },
        dateFormat
    }, useSortBy, useExpanded, usePagination);

    useEffect(() => {
        if (["created_at"].includes(sortBy[0]?.id))
            setSearchParams(() => ({ ...Object.fromEntries(searchParams.entries()), sort: sortBy[0].desc ? sortBy[0].id : `-${sortBy[0].id}`, page: 1 }));
    }, [sortBy]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (roles(user?.constants?.external_roles)) {
            setHiddenColumns(["technical_salesman", "unblockable_by"]);
        } else {
            setHiddenColumns([]);
        }
    }, [data]);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light overflow-x-scroll block xl:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    <span className='flex items-center'>
                                        <span className="flex-1">
                                            {column.render("Header")}
                                        </span>
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            // Use a React.Fragment here so the table markup is still valid
                            <React.Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).className, style: { width: cell.column.width, maxWidth: cell.column.maxWidth } })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {/*
                                    If the row is in an expanded state, render a row with a
                                    column that fills the entire length of the table.
                                */}
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length} className="border">
                                            {/*
                                                Inside it, call our renderRowSubComponent function. In reality,
                                                you could pass whatever you want as props to
                                                a component like this, including the entire
                                                table instance. But for this example, we'll just
                                                pass the row
                                            */}
                                            {renderRowSubComponent({ row, rowProps: row.getRowProps(), visibleColumns })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
