import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ImFilesEmpty } from "react-icons/im";
import Pagination from "../components/Pagination";
import { OffersTable } from "../components/offers/OffersTable";
import OffersSearchBar from "../components/offers/OffersSearchBar";
import { Loader } from "../components/Loader";
import useOffers from "../api/offers/useOffers";
import SubRowAsync from "../components/carts/SubRowAsync";
import { Section } from "../components/Section";
import { Slide, toast, ToastContainer } from "react-toastify";

type LocationState = {
    successMsg?: string
};

const Offers = () => {
    const location = useLocation();
    const locationState = location.state as LocationState;
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    // state
    const [successMsg, setSuccessMsg] = useState<string | null>(null);

    const { offers, isLoading, isFetching, isError } = useOffers(Object.fromEntries(searchParams.entries()));

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint={"offers"} />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (locationState?.successMsg) {
            setSuccessMsg(locationState.successMsg);
        }
    }, [locationState]);

    useEffect(() => {
        if (isError) {
            toast.error(t("Verifica che i dati inseriti nella form siano corretti"));
        }
    }, [isError]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title variant={Title.variant.primary}>{t("Le mie offerte")}</Title>

            {(isLoading || isFetching) && <Loader />}

            <Section title={"Cerca offerta"} className="mb-10">
                <OffersSearchBar setSearchParams={setSearchParams} />
            </Section>

            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />
                {
                    offers?.data?.length > 0 ?
                        <>
                            <OffersTable
                                items={offers?.data}
                                setSearchParams={setSearchParams}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                            <Pagination
                                currentPage={parseInt(searchParams.get("page") ?? "1", 10)}
                                totalCount={offers?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            <p className="text-2xl text-gray-600">{t("Nessuna offerta trovata")}</p>
                        </div>
                }
            </div>
        </div>
    );
};

export default Offers;
