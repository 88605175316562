import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../interfaces/Item";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import useOfferItemsMutations from "../../api/offers/useOfferItemsMutations";
import { EditableCellQty } from "../commom/EditableCellQty";
import { EditableCellSc } from "../commom/EditableCellSc";
import { toast } from "react-toastify";
import { UpdateProductSpecialParams } from "../../api/carts/useCartItemsMutations";
import client from "../../api/client";
import { IoIosWarning } from "react-icons/io";
import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

type CartTableProps = {
    cart: any,
    setIsTableValid: any,
    isTableValid: any,
    offerColumns: any,
    showMarginColumns: any,
};

const OfferTable: React.FC<CartTableProps> = ({ cart, offerColumns, showMarginColumns, setIsTableValid, isTableValid }) => {
    const { user, roles } = useAuthContext();

    const { t } = useTranslation();
    const { updateOfferProduct, updateProductSpecial, deleteOfferProduct } = useOfferItemsMutations();

    // state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setTableData([...cart?.data?.items].reverse());
    }, [cart]);

    const fetchUpdatedData = async () => {
        try {
            setIsLoading(true);

            const res = await client.get(`offers/${cart?.data?.id}/max-discount`);

            setTableData((prevData: any) => {
                const newData = prevData.map((row: any) => {
                    const updatedRow = res?.data?.data?.find((updated: any) => updated.id === row.id);
                    return updatedRow ? { ...row, ...updatedRow } : row;
                });
                return newData;
            });
        } catch (error) {
            console.error("Error fetching updated data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (cart?.data?.customer?.check_maximum_discount) {
            fetchUpdatedData();
        }
    }, [cart]);

    // UPDATE ITEM
    const handleUpdateItem = async (id: number, quantity: number, discount_1: string, discount_2: string, manualNetPrice: string) => {
        let body;

        if (roles(user?.constants?.external_roles)) {
            body = {
                quantity,
            };
        } else {
            body = {
                quantity,
                discount_1,
                discount_2,
                manualNetPrice
            };
        }

        await toast.promise(updateOfferProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: id, body: body }), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("La modifica non è stata effettuata");
                    }
                }
            }
        });
    };

    // UPDATE DESCRIPTION
    const handleUpdateSpecial = async (params: UpdateProductSpecialParams) => {
        await toast.promise(updateProductSpecial.mutateAsync(params), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("La modifica non è stata effettuata");
                    }
                }
            }
        });
    };

    // REMOVE ITEM
    const handleRemoveItem = useCallback(async (id: any) => {
        deleteOfferProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: id });
        setIsTableValid((prevState: any) => prevState.filter((cellId: any) => {
            return !cellId?.includes(id);
        }));
    }, [cart?.data?.id]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (parseInt(cart.data.kind_of_pricing) !== 1 && roles(user?.constants?.external_roles)) {
            setHiddenColumns((prevValues) => { return [...prevValues, "discount_1", "discount_2", "pricevalue"]; });
        }

        if (cart?.data?.customer?.organization_id === "IT001") {
            setHiddenColumns((prevValues) => { return [...prevValues, "materialpriceEUR", "Margin", "Total_margin"]; });
        }

        if (!(parseInt(cart.data.kind_of_pricing) !== 1 && roles(user?.constants?.external_roles)) && cart?.data?.customer?.organization_id !== "IT001") {
            setHiddenColumns([]);
        }
    }, [cart, showMarginColumns]);

    // TABLE DATA
    const data = useMemo(
        () => {
            const cartItems: Item[] = cart?.data?.items.filter((item: any) => {
                return item.code !== "PLATFORM" && item.code !== "PRIORITY" && item.code !== "RBCUFFIAPAL" && item.code !== "TELEPHONE" && item.code !== "AIRTRANSPORT" && item.code !== "TRANSPORT";
            }) ?? [];
            return [...cartItems].reverse();
        },
        [cart?.data?.items]
    );


    // TABLE COLUMNS
    const columns: Array<Column<Item>> = useMemo(
        () => offerColumns,
        [t, cart?.data?.items, user?.language_id, cart?.data?.total_net_price, cart?.data?.total_weight, hiddenColumns, showMarginColumns, offerColumns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data: tableData, initialState: { hiddenColumns: hiddenColumns, pageSize: 100 }, EditableCellQty, EditableCellSc, roles, setIsTableValid, isTableValid, handleUpdateItem, handleUpdateSpecial, handleRemoveItem, cart }, useSortBy, usePagination);

    return (
        <div className="overflow-x-auto">
            <table {...getTableProps()} className="mb-8 w-full text-xs font-light border">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);

                        if (isLoading) {
                            return <tr>
                                <td colSpan={row.cells.length}>
                                    <Skeleton className={`col-span-${row.cells.length}`} width={"100%"} height={89} />
                                </td>
                            </tr>
                        }

                        return (
                            <React.Fragment key={row.id}>
                                <tr className={`${row?.original?.maxDiscountExceeded && "[&>td]:bg-error-lighter"}`} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td
                                                {...cell.getCellProps({ className: (cell.column as any).cellClasses })}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                                {
                                    row?.original?.maxDiscountExceeded &&
                                    <tr className={`${row?.original?.maxDiscountExceeded && "bg-error-light"}`}>
                                        <td colSpan={row.cells.length} className="py-2">
                                            <div className="flex justify-center">
                                                <div className="flex items-center gap-2.5">
                                                    <IoIosWarning className="fill-red-500" />
                                                    <span>{t("Sconto massimo superato")}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </React.Fragment>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps({ className: (column as any).className })}>
                                    {column.render("Footer")}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    );
};

export default OfferTable;
