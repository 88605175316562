import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useOrderMutations } from "../api/orders/useOrderMutations";
import { Button } from "../components/Button";
import { CartClientInfo } from "../components/cart/CartClientInfo";
import ExtraServicesSummary from "../components/cart/step_3/ExtraServicesSummary";
import ShippingInfoSummary from "../components/cart/step_3/ShippingInfoSummary";
import ProductsSummary from "../components/commom/ProductsSummary";
import useOrder from "../api/orders/useOrder";
import { Loader } from "../components/Loader";
import CartCostsSummary from "../components/cart/step_3/CartCostsSummary";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../utils/getCurrencyDecimals";
import { convertMiliseconds, getExpiredDays } from "../utils/utils";
import RothoNotesSummary from "../components/cart/step_3/RothoNotesSummary";
import useBlockedOrder from "../api/orders/useBlockedOrder";
import DuplicationModal from "../components/templates/DuplicationModal";
import { UnblockModal } from "../components/orders/UnblockModal";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import Table from "../components/Table";
import { Section } from "../components/Section";
import { CgProfile } from "react-icons/cg";
import { Message } from "../components/Message";

const Order = ({ setTitle }: any) => {
    const { id } = useParams();
    const { user, roles } = useAuthContext();

    // hooks
    const { t } = useTranslation();
    const { unblockOrCancelOrder, duplicateOrder } = useOrderMutations();

    // state
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const [openDuplicateOrder, setOpenDuplicateOrder] = useState<any>(false);
    const [openBlockDetails, setOpenBlockDetails] = useState<Record<string, boolean>>({});

    // react query
    const { order, isLoading, isFetching } = useOrder(id);

    useEffect(() => {
        setTitle(t("Ordine {{order_number}}", { order_number: order?.data?.semiramis_number ? order?.data?.semiramis_number : order?.data?.id, }));
    }, [order]);

    const totalBlockedAmount =
        order?.data?.customer?.unprocessedOrders?.reduce(
            (acc: number, obj: any) => acc + parseFloat(obj.total_net_price),
            0
        );
    const residualCredit =
        order?.data?.customer?.finance?.creditlimit -
        order?.data?.customer?.finance?.unpaid -
        order?.data?.customer?.finance?.undelivered -
        order?.data?.customer?.finance?.deliverednotinvoiced -
        order?.data?.customer?.finance?.invoicednottransferred -
        totalBlockedAmount;

    const numberFormat = order && order?.data?.customer?.currency
        ? new Intl.NumberFormat(order?.data?.customer?.language_id, {
            style: "currency",
            currency: order?.data?.customer?.currency,
            minimumFractionDigits: getCurrencyDecimals(order?.data?.customer?.currency),
            maximumFractionDigits: getCurrencyDecimals(order?.data?.customer?.currency),
        })
        : null;

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const invoicesColumns = [
        {
            Header: t("Numero fattura").toString(),
            accessor: "number",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div>
                        <div className="font-normal">
                            {props?.row.original.number}
                        </div>
                        <div>{t(props?.row.original.type)}</div>
                    </div>
                );
            },
        },
        {
            Header: t("Data scadenza").toString(),
            accessor: "due_date",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return dateFormat.format(
                    new Date(props?.row.original.due_date)
                );
            },
        },
        {
            Header: t("Ritardo").toString(),
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return props.row.original.type === "Invoice" ? getExpiredDays(props.row.original.due_date) > 0 ? convertMiliseconds(getExpiredDays(props.row.original.due_date), "d") : "" : "";
            },
        },
        {
            Header: t("Totale").toString(),
            accessor: "amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.amount);
            },
        },
        {
            Header: t("Pagato").toString(),
            accessor: "paid_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.paid_amount);
            },
        },
        {
            Header: t("Aperto").toString(),
            accessor: "open_amount",
            className: "text-right px-4 py-2 border-b",
            Cell: (props: any) => {
                return numberFormat?.format(props.row.original.open_amount);
            },
        },
    ];

    const toggleBlockDetails = (blockId: string) => {
        setOpenBlockDetails(prevState => ({
            ...prevState,
            [blockId]: !prevState[blockId]
        }));
    };

    return (
        <div>
            {(isLoading || unblockOrCancelOrder.isLoading || isFetching || duplicateOrder.isLoading) && (
                <Loader />
            )}
            {order && (
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                    />

                    {/* DUPLICATE ORDER MODAL  */}
                    <DuplicationModal
                        label={t("Duplica ordine")}
                        modalState={openDuplicateOrder}
                        setModalState={setOpenDuplicateOrder}
                        data={order?.data}
                        type="order"
                    />

                    <CartClientInfo data={order} />
                    {/* spedizione consegna e pagamento */}
                    <ShippingInfoSummary data={order} numberFormat={numberFormat} summary={order?.data?.summary} />

                    {
                        order?.data?.blocks?.length > 0 &&
                        <Section title="Blocchi">
                            {
                                order?.data?.blocks?.map((block: any) => {
                                    return <div className="flex flex-col bg-white border p-4 rounded" key={block.id}>
                                        <div className="flex flex-wrap justify-between items-start">
                                            <div className="grid grid-cols-[auto,1fr] items-center gap-2">
                                                {
                                                    openBlockDetails[block.id] ?
                                                        <FaMinus
                                                            className="w-4 h-4 cursor-pointer"
                                                            onClick={() => toggleBlockDetails(block.id)}

                                                        />
                                                        :
                                                        <FaPlus
                                                            className="w-4 h-4 cursor-pointer"
                                                            onClick={() => toggleBlockDetails(block.id)}
                                                        />
                                                }
                                                <div className="flex gap-2">
                                                    <span className="font-semibold">
                                                        {t(block?.reason === "max-discount" ? block?.reason : block?.reason === "creditlimit" ? "Fuori fido" : "Insoluto")}
                                                    </span>
                                                    {
                                                        block?.unblockable_by &&
                                                        <div className="flex gap-1 items-center border text-sm px-2 rounded shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)]">
                                                            <CgProfile />
                                                            {
                                                                (!block?.unblocked_at && !block?.canceled_at) ?
                                                                    <span>{t("Autorizzabile da:")} {block?.unblockable_by}</span>
                                                                    :
                                                                    block?.unblocked_at ?
                                                                        <span>{t("Autorizzato da")}: {block?.managedBy?.name}</span>
                                                                        :
                                                                        <span>{t("Non autorizzato da")}: {block?.managedBy?.name}</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                {block?.unblocked_at && <><span></span> {/* Colonna vuota */}<span className="text-sm text-zinc-500">{t(block?.unblock_reason)}</span></>}
                                                {block?.canceled_at && <> <span></span> {/* Colonna vuota */}<span className="text-sm text-zinc-500">{t(block?.cancel_reason)}</span></>}
                                            </div>
                                            {
                                                (block?.unblocked_at || block?.canceled_at) &&
                                                <div className="flex items-center gap-x-2 border rounded-md w-fit px-3 py-0.5 shadow-[0px_1px_2px_0px_rgba(206,206,206,0.25)] text-sm">
                                                    {block?.unblocked_at && <div className="flex gap-2 items-center"><div className="rounded-full w-2 h-2 bg-green-500" />{t("Autorizzato")}</div>}
                                                    {block?.canceled_at && <div className="flex gap-2 items-center"><div className="rounded-full w-2 h-2 bg-red-500" />{t("Non autorizzato")}</div>}
                                                </div>
                                            }
                                        </div>

                                        <div className={`${openBlockDetails[block.id] ? "max-h-[1000px]" : "max-h-0"} transition-all duration-400 overflow-hidden`}>
                                            {
                                                block?.reason === "max-discount" ? <>
                                                    <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                        <div className="w-full overflow-x-scroll">
                                                            <table className="w-full border-collapse border overflow-x-scroll">
                                                                <thead className="bg-tertiary">
                                                                    <tr className="text-left border-b">
                                                                        <th className="px-4 py-2 whitespace-nowrap">{t("Codice articolo")}</th>
                                                                        <th className="px-4 py-2 whitespace-nowrap">{t("Quantità")}</th>
                                                                        <th className="px-4 py-2 whitespace-nowrap">{t("Sconto %")}</th>
                                                                        <th className="px-4 py-2 whitespace-nowrap">{t("Sconto 2 %")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {order?.data?.blocks
                                                                        ?.filter((block: any) => block?.reason === "max-discount")
                                                                        ?.flatMap((block: any) =>
                                                                            block?.blockedItems?.map((item: any) => (
                                                                                <tr key={item?.id} className="px-4 py-2">
                                                                                    <td className="px-4 py-2">{item?.product?.code}</td>
                                                                                    <td className="px-4 py-2">{item?.qty}</td>
                                                                                    <td className="px-4 py-2">{item?.discount_1}</td>
                                                                                    <td className="px-4 py-2">{item?.discount_2 ?? "-"}</td>
                                                                                </tr>
                                                                            ))
                                                                        )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </> : <div className="grid grid-cols-2">
                                                    {/* Display finance data and payment summary */}
                                                    <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                        <p>
                                                            {t("Insoluto")}:{" "}
                                                            <span className={`${order?.data?.customer?.finance?.unpaidexpired > 0 ? "text-rose-500" : ""}`}>
                                                                {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.unpaidexpired) : ""}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {t("Partite aperte")}:{" "}
                                                            {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.unpaid) : ""}
                                                        </p>
                                                        <p>
                                                            {t("Fido")}:{" "}
                                                            {numberFormat ? numberFormat?.format(order?.data?.customer?.finance?.creditlimit) : ""}
                                                        </p>
                                                        <p>
                                                            {t("Ordine attuale")}:{" "}
                                                            {numberFormat ? numberFormat?.format(order?.data?.total_net_price) : ""}
                                                        </p>
                                                        <p>
                                                            {t("Totale ordini bloccati E-service")}:{" "}
                                                            {numberFormat?.format(totalBlockedAmount)}
                                                        </p>
                                                    </div>
                                                    <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 flex flex-col gap-2 mt-4 col-span-1`}>
                                                        {/* Display more finance and rating data here */}
                                                        <p>
                                                            {t("Fido residuo")}:{" "}
                                                            <span className={`${residualCredit < 0 ? "text-rose-500" : ""}`}>
                                                                {numberFormat ? numberFormat?.format(residualCredit) : ""}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {t("Totale fatture ivate ultimi 24 mesi")}:{" "}
                                                            <span>
                                                                {numberFormat ? numberFormat.format(order?.data?.customer?.customerInvoices?.reduce((acc: number, obj: any) => acc + obj.netamount, 0)) : ""}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {t("Rothoblaas Rating")}:{" "}
                                                            <span className={order?.data?.customer?.invoicing?.internalrating === 3 ? "text-green-500" : order?.data?.customer?.invoicing?.internalrating === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                {order?.data?.customer?.invoicing?.internalrating === 0 && "No rating"}
                                                                {order?.data?.customer?.invoicing?.internalrating === 1 && "Red"}
                                                                {order?.data?.customer?.invoicing?.internalrating === 2 && "Yellow"}
                                                                {order?.data?.customer?.invoicing?.internalrating === 3 && "Green"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {t("Finance Rating")}:{" "}
                                                            <span
                                                                className={order?.data?.customer?.invoicing?.failurescore === 3 ? "text-green-500" : order?.data?.customer?.invoicing?.failurescore === 2 ? "text-yellow-500" : "text-rose-500"}>
                                                                {order?.data?.customer?.invoicing?.failurescore === 0 && "No rating"}
                                                                {order?.data?.customer?.invoicing?.failurescore === 1 && "Red"}
                                                                {order?.data?.customer?.invoicing?.failurescore === 2 && "Yellow"}
                                                                {order?.data?.customer?.invoicing?.failurescore === 3 && "Green"}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className={`${openBlockDetails[block.id] ? "opacity-100" : "opacity-0"} transition-opacity duration-400 col-span-2 mt-4`}>
                                                        {order && (
                                                            <Table
                                                                variants={Table?.variants?.expandable}
                                                                label="Estratto conto"
                                                                data={order?.data?.customer?.open_nav_payments}
                                                                columns={invoicesColumns}
                                                                emptyStateMessage="Nessuna fattura aperta"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            {
                                order?.data?.blocks?.some((block: any) => block?.canceled_at) &&
                                <Message open={true} variant={Message.variant.warning}>{t("Se uno o più blocchi non sono stati autorizzati puoi duplicare l'ordine per modificarlo.")}</Message>
                            }
                            {
                                order?.data?.blocks.length > 0
                                && !order?.data?.blocks?.some((block: any) => block?.canceled_at)
                                && !order?.data?.blocks?.every((block: any) => block?.unblocked_at)
                                && order?.data?.blocked
                                && !order?.data?.canceled_at
                                && !order?.data?.deleted_at
                                && (
                                    <Button
                                        variant={Button.variant.primary}
                                        onClick={() => setOpenUnblockModal(true)}
                                        className="w-fit"
                                    >
                                        {t("Sblocca ordine")}
                                    </Button>
                                )
                            }
                        </Section>

                    }

                    <div className="overflow-auto">
                        <ProductsSummary data={order} />
                    </div>

                    <ExtraServicesSummary data={order} />

                    <RothoNotesSummary data={order} />

                    <CartCostsSummary data={order} summary={order?.data?.summary} />

                    <div className="text-right space-x-2 mt-4">
                        <Button
                            variant={Button.variant.secondary}
                            onClick={() => setOpenDuplicateOrder(true)}
                        >
                            {t("Duplica ordine")}
                        </Button>
                    </div>

                    {!order?.data?.blocked && !order?.data?.canceled_at ? (
                        roles(user?.constants?.external_roles) ? (
                            <div className="text-xs text-primary-light py-2 px-6">
                                <p>{t("L'ordine è stato confermato - per eventuali modifiche contattare il TS")}</p>
                                <div className="flex gap-x-1">
                                    {
                                        <>
                                            {order?.data?.customer?.technicalSale?.name && (<p>{order?.data?.customer?.technicalSale?.name}{" "}</p>)}
                                            {order?.data?.customer?.technicalSale?.email && (<p>-{" "}{order?.data?.customer?.technicalSale?.email}</p>)}
                                            {order?.data?.customer?.technicalSale?.phone && (<p>-{" "}{order?.data?.customer?.technicalSale?.phone}</p>)}
                                        </>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="text-xs text-primary-light py-2 px-6 space-y-1">
                                <p>{t("L'ordine è stato confermato - per eventuali modifiche contattare il CS")}</p>
                                <div className="flex gap-x-1">
                                    {
                                        <>
                                            {order?.data?.customer?.assistant?.name && (<p>{order?.data?.customer?.assistant?.name}{" "}</p>)}
                                            {order?.data?.customer?.assistant?.email && (<p> -{" "}{order?.data?.customer?.assistant?.email}</p>)}
                                            {order?.data?.customer?.assistant?.phone && (<p> -{" "}{order?.data?.customer?.assistant?.phone}</p>)}
                                        </>
                                    }
                                </div>
                            </div>
                        )
                    ) : null}

                    <UnblockModal openUnblockModal={openUnblockModal} setOpenUnblockModal={setOpenUnblockModal} row_id={order?.data?.id} data={order?.data} />
                </>
            )
            }
        </div >
    );
};

export default Order;
