import { useQuery } from "@tanstack/react-query";
import client from "../client";
import { OfferSearchParams } from "../../components/offers/OffersSearchBar";

export default function useOffers(params: OfferSearchParams) {

    // GET OFFERS
    const fetchOffers = async (params: OfferSearchParams) => {
        const res = await client.get("offers", { params });
        return res.data;
    };

    const { data: offers, isLoading, isFetching, isError } = useQuery(["offers", params],
        () => fetchOffers(params),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        }
    );

    return { offers, isLoading, isFetching, isError };
}
